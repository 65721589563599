import localeMessage from '@/localeMessage';
import AppLayout from '@/layouts/app-layout.vue';

export const settingsRoutes = [
  {
    path: '/settings',
    component: AppLayout,
    children: [
      {
        path: 'employees',
        name: 'settings-employees',
        component: () => import('../views/settings/employees.vue'),
        meta: {
          title: localeMessage.get('employees'),
          requiresAuth: true
        }
      },
      {
        path: 'employees/new',
        name: 'settings-employees-add',
        component: () => import('../views/settings/employee.vue'),
        meta: {
          title: localeMessage.get('add_employee'),
          requiresAuth: true
        }
      },
      {
        path: 'employees/:id',
        name: 'settings-employees-edit',
        component: () => import('../views/settings/employee.vue'),
        meta: {
          title: localeMessage.get('edit_employee'),
          requiresAuth: true
        }
      },
      {
        path: 'category/list/:id?',
        name: 'category-list',
        component: () => import('../views/settings/categories.vue'),
        meta: {
          title: localeMessage.get('categories'),
          requiresAuth: true
        }
      },
      {
        path: 'category/new',
        name: 'category-new',
        component: () => import('../views/settings/category.vue'),
        meta: {
          title: localeMessage.get('categories'),
          requiresAuth: true
        }
      },
      {
        path: 'category/:id',
        name: 'category-edit',
        component: () => import('../views/settings/category.vue'),
        meta: {
          title: localeMessage.get('categories'),
          requiresAuth: true
        }
      },
      {
        path: 'product/list',
        name: 'product-list',
        component: () => import('../views/settings/products.vue'),
        meta: {
          title: localeMessage.get('products'),
          requiresAuth: true
        }
      },
      {
        path: 'product/:id',
        name: 'product-edit',
        component: () => import('../views/settings/product.vue'),
        meta: {
          title: localeMessage.get('product-name'),
          requiresAuth: true
        }
      },
      {
        path: 'product/new',
        name: 'product-new',
        component: () => import('../views/settings/product.vue'),
        meta: {
          title: localeMessage.get('product-name'),
          requiresAuth: true
        }
      },
      {
        path: 'discount/list',
        name: 'settings-discount-list',
        component: () => import('../views/settings/discounts.vue'),
        meta: {
          title: localeMessage.get('discounts'),
          requiresAuth: true
        }
      },
      {
        path: 'discount/new',
        name: 'settings-discount-new',
        component: () => import('../views/settings/discount.vue'),
        meta: {
          title: localeMessage.get('discount-name'),
          requiresAuth: true
        }
      },
      {
        path: 'discount/:id',
        name: 'settings-discount-edit',
        component: () => import('../views/settings/discount.vue'),
        meta: {
          title: localeMessage.get('edit_discount'),
          requiresAuth: true
        }
      },
    ]
  },
];